@import url(https://unpkg.com/@tailwindcss/typography@0.4.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100&display=swap);
body {
  height: 100vh;
  width: 100vw;
  font-family: "Poppins", sans-serif;
}

.prose-img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

